// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guides-tsx": () => import("./../../../src/pages/guides.tsx" /* webpackChunkName: "component---src-pages-guides-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-gallery-page-tsx": () => import("./../../../src/templates/GalleryPage.tsx" /* webpackChunkName: "component---src-templates-gallery-page-tsx" */),
  "component---src-templates-image-page-tsx": () => import("./../../../src/templates/ImagePage.tsx" /* webpackChunkName: "component---src-templates-image-page-tsx" */),
  "component---src-templates-location-details-page-component-tsx": () => import("./../../../src/templates/LocationDetailsPageComponent.tsx" /* webpackChunkName: "component---src-templates-location-details-page-component-tsx" */)
}

